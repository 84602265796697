import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectMedia,
  CSearch,
  CNewsList,
  CBtnList,
  CHeroSlider02,
  LPlan,
  CFixedImg,
  CSectTitle03,
  CTileImg,
  CFooterSearch,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';
import '../../assets/_sass/page/stay.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: '宿泊',
            sub: <>STAY</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <CSearch formId="global" />
      <section className="l_sect">
        <LWrap>
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: 'お知らせ',
            }}
          />
          <CNewsList data={infoChoice(infoGet(), ['宿泊'], 5)} label={false} />
          <CBtnList
            data={[
              {
                label: 'お知らせ一覧',
                color: 'borderBlack',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section style={{ overflow: 'hidden' }}>
        <LWrap>
          <div className="p_stayBgWrapper u_pb100 u_pb40_sp">
            <CHeroSlider02
              exClass="u_mb100"
              data={{
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/img_lounge.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_lounge02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_lounge03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_lounge04.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_lounge05.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_lounge06.png',
                      alt: '',
                    },
                  },
                ],
                link: {
                  href: '/stay/floor/lounge/',
                  blank: false,
                },
                title: <>エグゼクティブラウンジ</>,
                text: (
                  <>
                    ホテル高層階に位置するエグゼクティブフロアでは、ご宿泊者専用のエグゼクティブラウンジでのティータイム、カクテルタイムのほか限定特典をご用意。
                    お一人おひとりに寄り添い、心も身体も満たされるサービスをご提供いたします。
                  </>
                ),
              }}
            />
            <CSectMedia
              data={[
                {
                  id: 'suite',
                  img: {
                    src: '/assets/images/stay/img_stay.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/stay/img_stay__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/stay/floor/suite/',
                  },
                  title: <>スイート</>,
                  text: (
                    <>
                      ロイヤルパークホテルの歴史とともに歩んできた贅をこらしたスイート。普遍的な価値と美を舞台に、最高の時間をお届けいたします。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/stay/img_stay02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/stay/img_stay02__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/stay/floor/club_floor/',
                  },
                  title: <>エグゼクティブフロア</>,
                  text: (
                    <>
                      ホテル高層階に位置する、快適さを追求したエグゼクティブフロア。お一人おひとりに寄り添い、心も身体も満たされるサービスをご提供いたします。
                    </>
                  ),
                },
              ]}
            />
          </div>
          <CSectMedia
            data={[
              {
                img: {
                  src: '/assets/images/stay/img_stay03.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_stay03__sp.png',
                  alt: '',
                },
                link: {
                  href: '/stay/floor/standard_floor/',
                },
                title: <>スタンダードフロア</>,
                text: (
                  <>
                    スタンダードフロアの客室は都会的なセンスが光り、洗練された雰囲気は東京の上質のくつろぎの空間を演出しています。ゆったりとした時の流れに心洗われるご滞在をお約束いたします。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/img_stay04.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_stay04__sp.png',
                  alt: '',
                },
                link: {
                  href: '/stay/accessible/',
                },
                title: <>アクセシブルルーム</>,
                text: (
                  <>
                    すべてのお客様に使いやすい仕様のお部屋です。安全･快適なご滞在となりますよう、客室設備はもちろん、スタッフ一同お客様にご満足いただけるサービスをご提供してまいります。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_bgGray l_sect">
        <LWrap>
          <CSectTitle03 title="滞在中のサービス" exClass="border_dark" />
          <CTileImg
            data={[
              {
                img: {
                  src: '/assets/images/stay/img_service.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_service02.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            朝食やルームサービスのほか、記念日やお子様連れの方へ、きめ細やかなサービスをご提供いたします。
          </p>
          <CBtnList
            exClass="u_mb80_pc"
            data={[
              {
                label: '滞在中のサービス',
                link: {
                  href: '/stay/facility/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
        </LWrap>
        <LPlan />
        <LWrap>
          <CSectTitle03 title="街を、楽しむ" exClass="border_dark u_mt80_pc" />
          <p className="c_sectLead">
            歴史と伝統を持った粋な下町「日本橋・人形町」の文化に触れ、
            “ルームキーを持って”街を散策すると、老舗の魅力だけでなく素敵な特典も。
            <br />
            隅田川沿いのお散歩や七福神巡りなど、ご滞在中のひとときを存分にお楽しみください。
          </p>
          <CBtnList
            data={[
              {
                label: '街を、楽しむ',
                link: {
                  href: '/about/enjoy_the_city/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
        </LWrap>
      </section>
      <CFooterSearch formId="footer" />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: '宿泊',
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
